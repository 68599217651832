<template>
  <div>
    <ul class="reportDiv">
      <!--결과요약-->
      <li>
        <p class="styleTit wd00">결과 요약</p>
        <div class="div">
          <p class="litit">AI 기반 Cobb’s angle 분석 결과</p>
          <SpineCompo :record="record" :allImageLoaded="true" :isFirstRecord="record.isFirstSpine" :isReport="false" />
        </div>
        <div class="div">
          <p class="litit">AI 기반 뼈 나이 분석 결과</p>
          <HandsCompo :record="record" :allImageLoaded="true" :isFirstRecord="record.isFirstHands" :isReport="false" />
        </div>
      </li>
      <!--//결과요약-->

      <!--상세분석내용-->
      <li>
        <p class="styleTit wd00">상세 분석 내용</p>
        <div class="div">
          <p class="litit">{{ READING_SPINE_NAME }} 분석 결과</p>
          <div class="divi">
            <div class="lBox">
              <div class="imgW">
                <img :src="record.current.spineImgUrl" alt="현재 척추 엑스레이 이미지" @error="onErrorImage($event)">
              </div>
              <div class="txtW">
                <p class="date">{{ record.current.pictureDtStr }}</p>
                <p class="angle">1&rpar; {{ record.current.firstAngle }}º ({{ record.current.firstApex }}, {{ record.current.firstDirection }})</p>
                <p class="angle" v-if="record.current.secondAngle">2&rpar; {{ record.current.secondAngle }}º ({{ record.current.secondApex }}, {{ record.current.secondDirection }})</p>
                <p class="angle" v-if="record.current.thirdAngle">3&rpar; {{ record.current.thirdAngle }}º ({{ record.current.thirdApex }}, {{ record.current.thirdDirection }})</p>
              </div>
              <!-- <div class="txtW">
                <p class="date">{{ record.current.pictureDtStr }}</p>
                <p class="angle">1&rpar; {{ record.current.firstAngle }}º  {{ record.current.firstDirection }})</p>
                <p class="angle" v-if="record.current.secondAngle">2&rpar; {{ record.current.secondAngle }}º ({{ record.current.secondDirection }})</p>
                <p class="angle" v-if="record.current.thirdAngle">3&rpar; {{ record.current.thirdAngle }}º ({{ record.current.thirdDirection }})</p>
              </div> -->
            </div>
            <div class="rBox graphBox">
              <GraphCompo :xrayType="'spine'" :xrayNo="xrayNo" :isReport="false" />
            </div>
          </div>
        </div>
        <div class="div">
          <p class="litit">{{ READING_HANDS_NAME }} 분석 결과</p>
          <div class="divi">
            <div class="lBox">
              <div class="imgW">
                <img :src="record.current.handsImgUrl" alt="현재 수부 엑스레이 이미지" @error="onErrorImage($event)">
              </div>
              <div class="txtW">
                <p class="date">{{ record.current.pictureDtStr }}</p>
                <p class="angle">{{ READING_HANDS_NAME }} {{ record.current.boneAgeStr }}</p>
                <p class="angle">실제 연령 {{ record.current.atTimeAgeStr }}</p>
                <p class="angle">연령 차이 {{ record.current.ageDiff }}</p>
              </div>
            </div>
            <div class="rBox graphBox">
              <GraphCompo :xrayType="'hands'" :xrayNo="xrayNo" :isReport="false" />
            </div>
          </div>
        </div>
      </li>
      <!--//상세분석내용-->
    </ul>
    <button class="totalReportView btn solid small" @click="openReport($event)" v-ripple>통합 리포트 보기</button>

    <v-dialog v-model="reportVisible" persistent>
      <Report :onClick="() => { reportVisible = false }" :xrayNo="xrayNo" @closeReport="closeReport"
        :key="componentKey" />
    </v-dialog>
  </div>
</template>
<script>
import API_REPORT from '../API/record/report.js'
export default {
  components: {
    Report: () => import('../views/user/report/totReport.vue'),
    GraphCompo: () => import("@/components/graphCompo.vue"),
    SpineCompo: () => import("@/components/spineReportCompo.vue"),
    HandsCompo: () => import("@/components/handsReportCompo.vue"),
  },
  props: {
    xrayMap: {
      default() {
        return {
          spine: {},
          hands: {},
        };
      },
    },
    xrayNo: {
      default: ''
    }
  },
  data() {
    return {
      reportVisible: false,
      componentKey: 0,
      record: {
        current: {},
        firstSpine: {},
        firstHands: {},
        prev: {},
        isFirstSpine: false,
        isFirstHands: false,
      },
    }
  },
  computed: {},
  watch: {
  },
  created() { },
  async mounted() {
      await this.initData(this.xrayNo);
      this.getDate();
  },
  destroyed() { },
  methods: {
    getDate() {
      let today = new Date();
      let year = today.getFullYear();
      let month = String(today.getMonth() + 1).padStart(2, '0');
      let day = String(today.getDate()).padStart(2, '0');
      this.crtYear = year;
      this.today = year + month + day;
    },
    async initData(xrayNo) {
      if (this.isGettingItems) {
        return;
      }
      this.isGettingItems = true;
      let reportRes = await API_REPORT.request(xrayNo);
      if (reportRes.isSuccess) {
        this.patient = reportRes.patient;
        this.record.current = reportRes.currentRecord;
        this.record.isFirstSpine = reportRes.isFirstSpine;
        this.record.firstSpine = reportRes.firstSpineRecord;
        this.record.isFirstHands = reportRes.isFirstHands;
        this.record.firstHands = reportRes.firstHandsRecord;
        this.record.prev = reportRes.prevRecord;

        this.roundAngles(this.record.current);
        this.roundAngles(this.record.firstSpine);
        this.roundAngles(this.record.prev);

      } else {
        this.showPopup(reportRes.errorMsg, reportRes.status);
      }
    },
    openReport(event) {
      event.preventDefault();
      this.componentKey += 1;
      this.reportVisible = true;
    },
    closeReport() {
      this.reportVisible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../sass/app.scss";

.v-application {
  .blue, .red {background-color: transparent !important; border-color: transparent !important;}
  .totalReportView {position: absolute; right: 40px; top: 40px;}
}

.reportDiv::v-deep {
  .resultBox {
    display: flex; gap: 16px; flex-wrap: wrap; margin-top: 8px;
    ul.imgBox {
      width: calc(66% - 16px); display: flex; gap: 16px;
      li{width: 50%;}
    }
    .imgW {
      position: relative; padding-top: 140%; width: 100%; background: rgba(0, 0, 0, 0.6); border-radius: 4px; overflow: hidden;
      img {position: absolute; max-width: 100%; max-height: 100%; left: 50%; top: 50%; transform: translate(-50%,-50%);}
    }
    div.imgBox {width: calc(33% - 16px); margin-top: 0;}
    .date {
      margin-top: 8px; font-size: 1.2rem; text-align: center;
      &.em {color: var(--color-s80);}
    }

    .txtList {
      width: 33%; display: flex; flex-direction: column; gap: 16px;
      &.firstTextList{width: 66% !important}
      >li {
        display: flex; flex-direction: column; gap: 4px;
        .em {
          display: flex; align-items: center; justify-content: space-between; padding: 12px 16px; background: rgba(0, 0, 0, 0.8); border-radius: 4px; width: 100%;
          p {font-size: 1.4rem;}
          .rightTxt { color: var(--color-s80); font-weight: 600; font-size: 1.6rem;}
        }
        &.tit {
          display: flex; align-items: center; justify-content: space-between; font-size: 1.2rem;
          .stxt {font-size: 1.0rem; opacity: .8;}
        }
        .addEm{display:flex; flex-direction:column; gap:4px;
          .em{padding:8px 16px;
            p{font-size:1.2rem;}
            .rightTxt{color:var(--color-t80);}
          }
        }
        .red, .blue{background: transparent !important; }
      }
    }
    .resultInfo {
      display: flex; flex-direction: column; gap: 4px;
      >li { display: flex; align-items: center; justify-content: space-between; padding: 8px 16px; background: rgba(0, 0, 0, 0.8); border-radius: 4px; font-size: 1.2rem; overflow: hidden; width: 100%;}
    }
  }

  .divi {
    display: flex; gap: 16px; margin-top: 8px;
    .lBox {
      display: flex; flex-direction: column; gap: 8px; text-align: center; width: 150px;
      .imgW { 
        position: relative; width: 100%; height: 200px; background: rgba(0, 0, 0, 0.6); border-radius: 4px; overflow: hidden;
        img { position: absolute; max-width: 100%; max-height: 100%; left: 50%; top: 50%; transform: translate(-50%,-50%);}
      }
      .txtW {
        display: flex; flex-direction: column; gap: 4px; align-items: center; justify-content: center; font-size: 1.2rem;
        .date {color: var(--color-s80);}
      }
      .graphBox {background: rgba(0, 0, 0, 0.8); border-radius: 4px; min-height: 250px; width: 100%;}
    }
    .rBox {width: calc(100% - 166px);}
  }
  .flextit {
    display: flex; align-items: center; justify-content: space-between;
    .stxt {font-size: 1.2rem; opacity: .8;}
  }

  #apexchartsvuechart-example{
    .apexcharts-xaxis-texts-g>text, .apexcharts-yaxis-texts-g>text{fill: #fff;}
    .apexcharts-legend-series > span.apexcharts-legend-text{color: #fff !important; }
  }
}
</style>